import { createTheme } from '@mui/system'
import defaultTheme from '@bluheadless/ui/src/theme/default'
import { createTheme as createMuiTheme } from '@mui/material/styles' // keep @mui/material/styles to use default theme of @mui/material
import Button from './theme/components/button'

export const fontSize = 14
export const htmlFontSize = 14

const muiTheme = createMuiTheme({ typography: { htmlFontSize, fontSize } })

const theme = createTheme(defaultTheme, {
	components: {
		...Button,
	},
	palette: {
		primary: { main: '#000' },
		secondary: { main: '#005189' },
		text: {
			secondary: '#005189',
		},
	},
	typography: {
		headlinebig: {
			fontSize: muiTheme.typography.pxToRem(40),
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: muiTheme.typography.pxToRem(40),
				lineHeight: 'calc(49/40)',
			},
		},
		headlinemedium: {
			fontSize: muiTheme.typography.pxToRem(30),
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: muiTheme.typography.pxToRem(30),
				lineHeight: 'calc(37/30)',
			},
		},
		headlinesmall: {
			fontSize: muiTheme.typography.pxToRem(24),
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: muiTheme.typography.pxToRem(24),
				lineHeight: 'calc(29/24)',
			},
		},
		subheadline2: {
			fontWeight: 700,
		},
	},
})

export default theme
