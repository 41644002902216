const Button = {
	MuiButton: {
		styleOverrides: {
			containedPrimary: {
				backgroundColor: '#005189',
			},
			outlinedPrimary: {
				borderColor: '#005189',
				color: '#005189',
				'&:hover': {
					backgroundColor: '#005189',
					borderColor: '#005189',
				},
			},
			outlinedSecondary: {
				borderColor: '#000',
				color: '#000',
				'&:hover': {
					backgroundColor: '#000',
					borderColor: '#000',
				},
				'&:focus': {
					backgroundColor: '#000',
					borderColor: '#000',
				},
				'&:active': {
					backgroundColor: '#000',
					borderColor: '#000',
				},
			},
		},
	},
}

export default Button
